@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

user-info {
  a {
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 1;

    &:not([href]) {
      cursor: default;
      color: $body-font-color;

      .profile-image {
        color: $body-font-color;
        box-shadow: 0 0 0 1px $body-font-color;
      }
    }
  }

  .profile-image {
    border-radius: 32px;
    background-color: $white;
    color: $primary-color;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    line-height: 32px;
    height: 32px;
    width: 32px;
    min-width: 32px;
    box-shadow: 0 0 0 1px $medium-gray;
    overflow: hidden;
    position: relative;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      @include sharpen-image();
    }
  }

  .details {
    margin-left: 0.5rem;
    line-height: 1.25;
  }

  .company {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .date-time {
    font-size: 0.75rem;
    color: $medium-gray;
  }
}